import Watchlist from "./screens/Watchlist";
import ChatWindow from "./screens/ChatWindow";
import ChatGroup from "./screens/ChatGroup";
import NewsTab from "./screens/NewsTab";
import Sidebar from "./screens/Sidebar";
import { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import Login from "./screens/Login";
import { database } from "./firebase";
import { actionTypes } from "./reducer";
import UnderMaintenance from "./screens/UnderMaintenance";
import { onValue, ref, update, get } from "firebase/database";
import SearchScreen from "./screens/SearchScreen";
import { useNavigate } from "react-router-dom";
import {isMobile, isAndroid} from 'react-device-detect';
import FullScreenLoader from "./FullScreenLoader";

function App() {
  //console.log(process.env.REACT_APP_SERVER_ADDRESS);
  const [{ user, chat, config, send, search }, dispatch] = useStateValue();
  const [maintenance, setmaintenance] = useState(false);
  const [isshare, setisshare] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showCount, setShowCount] = useState(0);  // To track the count of the dialog showing up
  const [interactionCount, setInteractionCount] = useState(0);
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(false);

  const handleBeforeUnload = (event) => {
    if (hasInteracted && !isMobile) {
      // Increment the show count when the dialog is triggered
      setShowCount(prevCount => {
        const newCount = prevCount + 1

        // Update Firebase with the new count
        update(ref(database, `/webanalytics/`), { DialogBoxCount: newCount })
        .then(() => {
          console.log('Firebase updated successfully');
        })
        .catch(error => {
          console.error('Error updating Firebase:', error);
        });
        return newCount; // Ensure the state is updated correctly
      });

      const message = "Are you sure you want to leave without saving your changes?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    } 
  };

  const handleUserInteraction = () => {
    setHasInteracted(true);
  };
  
  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchDialogBoxCount = async () => {
      const countRef = ref(database, "/webanalytics/DialogBoxCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setShowCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchDialogBoxCount();
  }, []);

  useEffect(() => {
    const fetchInteractionCount = async () => {
      const countRef = ref(database, "/webanalytics/InteractionCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setInteractionCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchInteractionCount();
  }, []);

  useEffect(() => {
    if (hasInteracted && !isMobile) {
      setInteractionCount(prevCount => {
        const newCount = prevCount + 1
        // Update Firebase with the new count
        update(ref(database, `/webanalytics/`), { InteractionCount: newCount })
        .then(() => {
          console.log('Firebase updated successfully');
        })
        .catch(error => {
          console.error('Error updating Firebase:', error);
        });
        return newCount; // Ensure the state is updated correctly
      });
    }
    // Add the event listener for beforeunload when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Also listen for user interaction events (like clicks or keypresses)
    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('mousemove', handleUserInteraction);
    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, [hasInteracted]);

  console.log('interaction?', hasInteracted);
  console.log('showCount', showCount);
  console.log('interactionCount', interactionCount);
  useEffect(() => {
    if (window.location.href === 'https://opiniontrade.ai/termsandcondition.html') {
          window.location.replace(`https://www.opiniontrade.ai${window.location.pathname}`);
    }
    const messageInfo = ref(database, `/config/`);
    onValue(messageInfo, async (snapshot) => {
      if (!snapshot.exists) {
        setmaintenance(false);
      } else {
        let snapVal = snapshot.val();
        setmaintenance(snapVal.maintenance);
        dispatch({
          type: actionTypes.SET_CONFIG,
          config: snapVal,
        });
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let storeduser = localStorage.getItem("user");
      if (storeduser == []) {
      } else {
        dispatch({
          type: actionTypes.SET_USER,
          user: JSON.parse(storeduser),
        });
      }

      if (isMobile && !isAndroid){
        window.location.href = "https://apps.apple.com/us/app/opiniontrade/id1530699357";
      }
      
      if (window.location.href.startsWith('https://opiniontrade.ai/')) {
        window.location.href = `https://www.opiniontrade.ai${window.location.pathname}`;
      }
      
      if (window.location.pathname.startsWith("/share/")) {
        if (isMobile) {
          setisshare(true);
          window.open("opiniontrade:" + window.location.pathname);
        } else {
          if (window.location.href.includes('https://opiniontrade.ai/')) {
            window.location.href = window.location.origin.replace("//", "//www.") + window.location.pathname
          }
          if (JSON.parse(storeduser)) {
            setLoading(true);
            try {
              let response = await fetch(
                `${
                  process.env.REACT_APP_SERVER_ADDRESS
                }/opinion/getNews?news=${window.location.pathname.replace(
                  "/share/",
                  ""
                )}`
              );
              let dataJSON = await response.json();

              if (dataJSON && dataJSON.length > 0) {
                let domain = new URL(dataJSON[0].newsLink);
                let sharedSite = domain.hostname;
                let news = {
                  newsTitle: dataJSON[0].newsTitle,
                  newsSite: sharedSite,
                  newsLink: dataJSON[0].newsLink,
                  aisummary: dataJSON[0].aisummary,
                };
                dispatch({
                  type: actionTypes.SET_SHARED_NEWS,
                  news,
                });
                dispatch({
                  type: actionTypes.SEND_NEWS,
                  sendenabled: true,
                  news,
                  room: [],
                });
              } else {
                throw new Error("Empty data from first API");
              }
            } catch (error) {
              console.error("Error fetching data from first API:", error);

              try {
                let response = await fetch(
                  `https://generativeai.opiniontrade.ai/api/scrape?url=${window.location.pathname.replace(
                    "/share/",
                    "")}`
                );
                let dataJSON = await response.json();
                let domain = new URL(
                  window.location.pathname.replace("/share/", "")
                );
                let sharedSite = domain.hostname;
                // Await the result of fetchStockQuotes
                let refStockQuote = await fetchStockQuotes(
                  dataJSON.associatedStocks
                );
                let aisummary = dataJSON.summary;
                let news = {
                  newsTitle: dataJSON.title,
                  newsSite: sharedSite,
                  newsLink: window.location.pathname.replace("/share/", ""),
                  refStockQuote: refStockQuote,
                  aisummary: aisummary,
                };
                dispatch({
                  type: actionTypes.SET_SHARED_NEWS,
                  news,
                });
                dispatch({
                  type: actionTypes.SEND_NEWS,
                  sendenabled: true,
                  news,
                  room: [],
                });
              } catch (error) {
                console.error("Error fetching data from second API:", error);
                let domain = new URL(
                  window.location.pathname.replace("/share/", "")
                );
                let sharedSite = domain.hostname;
                let news = {
                  newsTitle: sharedSite,
                  newsSite: sharedSite,
                  newsLink: sharedSite,
                };
                dispatch({
                  type: actionTypes.SET_SHARED_NEWS,
                  news,
                });
                dispatch({
                  type: actionTypes.SEND_NEWS,
                  sendenabled: true,
                  news,
                  room: [],
                });
              }
            }
            setLoading(false);
          } else {
            let domain = new URL(
              window.location.pathname.replace("/share/", "")
            );
            let sharedSite = domain.hostname;
            let response = await fetch(
              `${
                process.env.REACT_APP_SERVER_ADDRESS
              }/opinion/title?news=${window.location.pathname.replace(
                "/share/",
                ""
              )}`
            );
            let dataJSON = await response.json();
            let news = {
              newsTitle: dataJSON.title,
              newsSite: sharedSite,
              newsLink: dataJSON.url,
            };
            dispatch({
              type: actionTypes.SET_SHARED_NEWS,
              news,
            });
            dispatch({
              type: actionTypes.SEND_NEWS,
              sendenabled: true,
              news,
              room: [],
            });
          }
        }
      }
    };

    fetchData();
  }, []);

  const fetchStockQuotes = async (refStock) => {
    let refStockQuote = [];
    if (refStock) {
      let url = `${
        process.env.REACT_APP_SERVER_ADDRESS
      }/opinion/v1/stocksQuote?ticker=${refStock.replace(/, /g, "&ticker=")}`;
      console.log("url!", url);
      try {
        let response = await fetch(url);
        let dataJson = await response.json();
        refStockQuote = dataJson;
      } catch (err) {
        console.log("fetching stock quote error", err);
        refStockQuote = [];
      }
    }

    console.log("refstockquote", refStockQuote);
    return refStockQuote;
  };


  if (isMobile){
    return (
      <main
        className={`flex flex-col w-screen h-screen ${
          user ? "bg-[#f3f4f6]" : "bg-[#f3f4f6]"
        }`}
      >
        {maintenance ? (
          <UnderMaintenance />
        ) : (
          <div className="flex flex-col sm:flex-row w-screen h-screen">
            {send.enabled ? (
              <div
                className="w-full h-full absolute bg-black opacity-[50%] z-[99]"
                onClick={() => {
                  dispatch({
                    type: actionTypes.SEND_NEWS,
                    sendenabled: false,
                    news: [],
                    room: [],
                  });
                  dispatch({
                    type: actionTypes.SET_SHARED_NEWS,
                    news: {
                      newsTitle: "",
                      newsLink: "",
                      newsSite: "",
                    },
                  });
                  dispatch({
                    type: actionTypes.SET_COMMENT,
                    comment: "",
                  });
                }}
              ></div>
            ) : null}
            <Sidebar />
            <NewsTab mobileTab={7} />
          </div>
        )}
        {isshare ? (
          <div className="w-full h-full flex sm:hidden absolute items-end backdrop-blur-sm z-50">
            <div className="bg-white w-full h-64 flex justify-center items-center flex-col rounded-tl-[10px] rounded-tr-[10px] border-t-4 border-[#1ab394]">
              <div className="flex flex-row justify-center items-center w-16 mx-auto my-2 sm:my-10 p-2 bg-white rounded-2xl text-white z-20">
                <img
                  src={require("./assets/logo.png")}
                  loading="lazy"
                  className="h-full w-full object-cover"
                />
                <p className="text-black relative sm:hidden ml-2 text-xl font-bold text-[#1ab394]">
                  OpinionTrade
                </p>
              </div>
              <p className="text-xl font-bold mb-6">
                Get the full experience on the app
              </p>
              <div
                className="bg-[#1ab394] text-white rounded mb-4 py-[10px] px-[50px]"
                onClick={() => {
                  setTimeout(function () {
                    window.location = config.applink;
                  }, 25);
                  window.location = "opiniontrade:" + window.location.pathname;
                }}
              >
                <p className="">Open OpinionTrade</p>
              </div>
              <div className="" onClick={() => setisshare(false)}>
                <p className="">Not now</p>
              </div>
            </div>
          </div>
        ) : null}
      </main>
    );
  }

  return (
    <main
      className={`flex flex-col w-screen h-screen ${
        user ? "bg-[#f3f4f6]" : "bg-[#f3f4f6]"
      }`}
    >
      {loading && <FullScreenLoader />}
      {maintenance ? (
        <UnderMaintenance />
      ) : (
        <div className="flex flex-col sm:flex-row w-screen h-screen">
          {send.enabled ? (
            <div
              className="w-full h-full absolute bg-black opacity-[50%] z-[99]"
              onClick={() => {
                dispatch({
                  type: actionTypes.SEND_NEWS,
                  sendenabled: false,
                  news: [],
                  room: [],
                });
                dispatch({
                  type: actionTypes.SET_SHARED_NEWS,
                  news: {
                    newsTitle: "",
                    newsLink: "",
                    newsSite: "",
                  },
                });
                dispatch({
                  type: actionTypes.SET_COMMENT,
                  comment: "",
                });
              }}
            ></div>
          ) : null}
          {search.enabled ? <SearchScreen /> : null}
          <Sidebar />
          <NewsTab />
          {user ? <ChatGroup /> : null}
          {user && chat.enabled ? <ChatWindow /> : null}
          {user ? <Watchlist /> : <Login />}
        </div>
      )}
      {/* <Footer /> */}
      {isshare ? (
        <div className="w-full h-full flex sm:hidden absolute items-end backdrop-blur-sm z-50">
          <div className="bg-white w-full h-64 flex justify-center items-center flex-col rounded-tl-[10px] rounded-tr-[10px] border-t-4 border-[#1ab394]">
            <div className="flex flex-row justify-center items-center w-16 mx-auto my-2 sm:my-10 p-2 bg-white rounded-2xl text-white z-20">
              <img
                src={require("./assets/logo.png")}
                loading="lazy"
                className="h-full w-full object-cover"
              />
              <p className="text-black relative sm:hidden ml-2 text-xl font-bold text-[#1ab394]">
                OpinionTrade
              </p>
            </div>
            <p className="text-xl font-bold mb-6">
              Get the full experience on the app
            </p>
            <div
              className="bg-[#1ab394] text-white rounded mb-4 py-[10px] px-[50px]"
              onClick={() => {
                setTimeout(function () {
                  window.location = config.applink;
                }, 25);
                window.location = "opiniontrade:" + window.location.pathname;
              }}
            >
              <p className="">Open OpinionTrade</p>
            </div>
            <div className="" onClick={() => setisshare(false)}>
              <p className="">Not now</p>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
}

export default App;
