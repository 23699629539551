import "../src/components/styles/FullScreenLoader.css";

const FullScreenLoader = () => {
    return (
      <div style={styles.overlay}>
        <div style={styles.content}>
          <p style={styles.text}>Hang tight, our AI is processing this shared news for the first time </p>
          <div style={styles.loader}></div>
        </div>
      </div>
    );
  };
  
  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    content: {
      textAlign: "center",
      color: "#fff",
    },
    text: {
      fontSize: "20px",
      marginBottom: "20px",
    },
    loader: {
      width: "50px",
      height: "50px",
      border: "5px solid #f3f3f3",
      borderTop: "5px solid #3498db",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
  };
  
export default FullScreenLoader;
  